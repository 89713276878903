
body {
  background-color: #282a36;
}

.App {
    background-color: #282a36;
}

.app-header {
  background-color: #282a36;
}

.modal-dark {
  background-color: #282a36;
  color: #f8f8f2
}

.connection-error {
  color: #ff5555
}

.newsSourceTitle {
  textDecoration: 'underline';
  cursor: pointer;
  color: #bd93f9
}

.change_order_link {
  color: #F1FA8C;
  cursor: pointer;
}

.edit-order-entry {
  color: white;
}

.title-color {
  color: #bd93f9;
  border-color: #6272a4;
}

.newsStoryHeadline {
  display: inline-block;
  color: #8be9fd
}
.newsStoryHeadline:hover {
  color: #6272a4;
  text-decoration: none;
}

.tooltipText {
  color: #ff79c6;
}

.modal-header.title-color {
  align-items: start;
}

.modal-header .btn-close {
  background-color: #ffb86c;
  opacity: 1;
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
  border-radius: 0.25rem;
  width: 1em;
  height: 1em;
  box-sizing: content-box;
  position: absolute;
}

.modal-header .btn-close:focus {
  box-shadow: 0 0 0 0.2rem rgb(252, 159, 62);
}

.storyDialogLink {
  color: #ff79c6;
}

.storyDialogLink:hover {
  color: #ff79c6;
  text-decoration: none;
}

.storyContent {
  font-size: 1.25rem;
}

.showMoreLess {
  display: inline-block;
  color: #ffb86c;
}

.showMoreLess:hover {
  text-decoration: none;
  color: #ffb86c;
}

hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 1px;
  margin-top: 1px;
  padding-bottom: 1px;
  padding-top: 1px;
}

a {
  font-size: 16px;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
