
body {
  background-color: #282a36;
}

h3 {
  color: lightblue;
}

.App-header {
  background-color: #282a36;
  /*min-height: 100vh;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  /*font-size: calc(10px + 2vmin);*/
  /*color: white;*/
}

hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 1px;
  margin-top: 1px;
  padding-bottom: 1px;
  padding-top: 1px;
}

a {
  font-size: 16px;
}
